var stickyHeader = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var inlineNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// var sidebarCards = false; // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

// var visualAppealsLoadMore = false;

$(document).ready(function () {
  // header search append to menu admin
  $("#pageHeader .headerContent .header-search").appendTo("#menuAdmin");
  $("#pageHeader > .menuAdminContainer").prependTo("#menuMain");

  // move menu to between logo and main CTA
  function handleMenu() {
    if ($(window).width() > 1299) {
      // Check if the menu's parent is the header content or not
      if (!$("#menuMain").parent().is($("#pageHeader .headerContent"))) {
        // Else append the menu to the header content
        $("#menuMain").appendTo("#pageHeader .headerContent");
      }
      if (!$("#menuAdmin").parent().is($("#pageHeader .menuAdminContainer"))) {
        // Else append the menu to the header content
        $("#menuAdmin").appendTo("#pageHeader .menuAdminContainer");
      }
    } else {
      // Check if the menu is inserted after or not
      if (!$("#pageHeader .headerContent").next().is($("#menuMain"))) {
        // Else insert the new element after the target element
        $("#menuMain").insertAfter("#pageHeader .headerContent");
      }
      if (!$("#menuMain .header-search").next().is($("#menuAdmin"))) {
        // Else insert the new element after the target element
        $("#menuAdmin").prependTo("#menuMain");
      }
    }
  }
  // Initial call to handleScreenSize
  handleMenu();
  // Attach the handleScreenSize function to the window resize event
  $(window).on("resize", handleMenu);

  // Move sub banner descriptions out to below with the content
  if ($(".listingIntro").length) {
    $(".listingIntro").prependTo(".pageWrapper > section > .contentBlockWrapper > .contentBlock");
    $(".listingIntro").prependTo(".appealListingPage .donorsListWrapper");
    $(".listingIntro").prependTo(".postCreate > .contentBlockWrapper > .contentBlock");
  }

  // footer: address + enquiries + social medias + awards
  const ftrLinks = $("#pageFooterWrapper .Usefullinks");
  const ftrContact = $("#pageFooterWrapper .Contact");
  const ftrfollowus = $("#pageFooterWrapper .Footer-Followus");
  const ftrlogoimg = $("#pageFooterWrapper .FooterLogoImages");
  // adding div to usefullinks
  ftrLinks.wrapInner('<div class="linkwrapper"></div>');
  ftrContact.appendTo(ftrLinks);
  ftrfollowus.appendTo(ftrLinks);
  ftrlogoimg.appendTo(ftrLinks);

  // home intro section
  const homeIntroBox = $(".homeIntroWrapper");
  homeIntroBox.wrapInner('<div class="homeBoxed"></div>')
  const homeCarousel = $("body.homepage .carouselImageMultiple");
  homeIntroBox.insertBefore(homeCarousel);

  // support & empower section
  const homeSupportEmpower = $(".homefeaturecategory-homesupportempower");
  homeSupportEmpower
    .wrapInner('<div class="wrapper"></div>')
    .wrapInner('<div class="boxed"></div>');

  // questions section
  const questionsTitle = $(".homefeaturecategory-questiontitle");
  questionsTitle.wrapInner('<div class="title-wrapper"></div>');
  const questionsFeed = $(".homeFeed.QuestionsFeed");
  questionsFeed.appendTo(questionsTitle);
  // wrap another div as its own
  questionsTitle.wrapInner('<div class="boxed"></div>');

  // stories section
  const storiesFeed = $(".homeFeed.StoriesFeed");
  storiesFeed.appendTo(questionsTitle);

  // events section
  const eventsTitle = $(".homefeaturecategory-eventstitle");
  eventsTitle.wrapInner('<div class="title-wrapper"></div>');
  const eventsFeed = $(".homeFeed.EventsFeed");
  eventsFeed.appendTo(eventsTitle);

  // stats section
  const stsimage = $(".homefeaturecategory-homestatsimage");
  const stsbubble = $(".homefeaturecategory-homestatsbubble");
  const stsstats = $(".homefeaturecategory-homeboximpactstats");
  // adding div wrapper to stats overall
  stsimage.wrapInner('<div class="statsbox"></div>');
  stsbubble.appendTo(stsimage);
  stsstats.appendTo(stsimage);

  // for the two cta boxes
  const ctaOneContent = $(".homefeaturecategory-ctaonecontent");
  const ctaTwoContent = $(".homefeaturecategory-ctatwocontent");
  // wrap article and wrapper for cta one
  ctaOneContent.wrapInner("<article></article>");
  ctaOneContent.wrapInner('<div class="cta-onebox"></div>');
  // wrap article and append together with cta one
  ctaTwoContent.wrapInner("<article></article>");
  ctaTwoContent.appendTo(ctaOneContent);
  // finally wrap the entire section
  ctaOneContent.wrapInner('<div class="cta-wrapper"></div>');

  // Stories Carousel Feed
  if ($(".homeFeed.StoriesFeed").length) {
    let prevArrow = '<button type="button" class="prev-slide"></button>';
    let nextArrow = '<button type="button" class="next-slide"></button>';
    let dotCustom = '<div class="dot-wrapper"></div>'
    let sliderNav = '<div class="slider-nav">' + prevArrow + dotCustom + nextArrow + '</div>';
    $(".homeFeed.StoriesFeed").append(sliderNav);
    $(".homeFeed.StoriesFeed .feedList").slick({
      fade: true,
      dots: true,
      appendDots: $(".homeFeed.StoriesFeed .dot-wrapper"),
      arrows: true,
      prevArrow: $(".homeFeed.StoriesFeed .prev-slide"),
      nextArrow: $(".homeFeed.StoriesFeed .next-slide"),
      infinite: true,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplaySpeed: 4000
    });
  }

  // replace the following texts:
  // ---------------------------------
  if ($(".shareWrapper > strong").length) {
    // share this post
    $(".shareWrapper > strong").html("Like it? Share it.");
  }
  // associated post
  if ($(".associated:not(.associatedBox) > h2").length) {
    // latest post
    $(".associatedLatestPosts:not(.associatedBox) > h2").html("Latest posts.");
    // related post
    $(".associatedRelatedPosts:not(.associatedBox) > h2").html("Related posts.");
    // most read post
    $(".associatedMostRead:not(.associatedBox) > h2").html("Most read posts.");
    // tag cloud post
    $(".associatedTagCloud:not(.associatedBox) > h2").html("Tag cloud posts.");
  }
});
